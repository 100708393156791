import * as React from "react";

import { ReactElement } from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function Diagram(): ReactElement {
  return (
    <StaticImage
      src={"https://s.pharmiq.co/dp7laJkGxgjG.png"}
      alt={"PharmIQ data map"}
    />
  );
}
