import React, {ReactElement} from "react";

export default function SubmissionFailure(): ReactElement {
    return(

<svg width="95%" height="95%" viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_6768_3586)">
<path d="M83.0496 189.24H77.7024V189.36H83.0496V189.24Z" fill="#EBEBEB"/>
<path d="M33 197H18V198H33V197Z" fill="#EBEBEB"/>
<path d="M107.04 170H6.95452C6.17046 169.999 5.41898 169.685 4.86505 169.129C4.31111 168.572 4 167.818 4 167.032V25.9421C4.00683 25.1603 4.32095 24.4128 4.87415 23.8619C5.42734 23.3111 6.17493 23.0013 6.95452 23H107.04C107.825 23 108.578 23.3127 109.133 23.8693C109.688 24.4259 110 25.1809 110 25.9681V167.032C110 167.819 109.688 168.574 109.133 169.131C108.578 169.687 107.825 170 107.04 170ZM6.95452 23.104C6.20483 23.1053 5.48631 23.405 4.95668 23.9371C4.42704 24.4692 4.12958 25.1903 4.12958 25.9421V167.032C4.12958 167.784 4.42704 168.505 4.95668 169.037C5.48631 169.569 6.20483 169.869 6.95452 169.87H107.04C107.79 169.869 108.51 169.569 109.04 169.037C109.57 168.505 109.869 167.784 109.87 167.032V25.9421C109.869 25.1898 109.57 24.4687 109.04 23.9367C108.51 23.4048 107.79 23.1053 107.04 23.104H6.95452Z" fill="#EBEBEB"/>
<path d="M220.023 170H118.988C118.196 169.999 117.437 169.685 116.877 169.129C116.317 168.573 116.001 167.819 116 167.032V25.9421C116.008 25.1598 116.327 24.4123 116.886 23.8616C117.445 23.3109 118.2 23.0013 118.988 23H220.023C220.809 23.0027 221.562 23.3131 222.119 23.8638C222.677 24.4145 222.993 25.1612 223 25.9421V167.032C223 167.817 222.687 168.571 222.129 169.127C221.571 169.683 220.813 169.997 220.023 170ZM118.988 23.104C118.23 23.1053 117.505 23.4048 116.969 23.9367C116.434 24.4687 116.132 25.1898 116.131 25.9421V167.032C116.132 167.784 116.434 168.505 116.969 169.037C117.505 169.569 118.23 169.869 118.988 169.87H220.023C220.78 169.869 221.506 169.569 222.041 169.037C222.577 168.505 222.878 167.784 222.88 167.032V25.9421C222.878 25.1898 222.577 24.4687 222.041 23.9367C221.506 23.4048 220.78 23.1053 220.023 23.104H118.988Z" fill="#EBEBEB"/>
<path d="M31.0464 101.809L109.118 101.809L109.118 36.1302L31.0464 36.1302L31.0464 101.809Z" fill="#E6E6E6"/>
<path d="M16 105L101 105L101 34L16 34L16 105Z" fill="#F0F0F0"/>
<path d="M31.0464 104.626L109.118 104.626V101.808L31.0464 101.808V104.626Z" fill="#E6E6E6"/>
<path d="M12 108L98 108V105L12 105V108Z" fill="#F0F0F0"/>
<path d="M105.653 99.2115V38.7363L31.7952 38.7363V99.2115H105.653Z" fill="#FAFAFA"/>
<path d="M75.6815 99.2115L68.4431 38.7363H57.1487L64.3871 99.2115H75.6815Z" fill="white"/>
<path d="M104.448 96.6249C104.497 96.6236 104.544 96.6035 104.579 96.5687C104.614 96.5338 104.634 96.4869 104.635 96.4377V40.4265C104.635 40.3768 104.615 40.3292 104.58 40.2941C104.545 40.259 104.498 40.2393 104.448 40.2393C104.398 40.2393 104.351 40.259 104.316 40.2941C104.28 40.3292 104.261 40.3768 104.261 40.4265V96.4377C104.262 96.4869 104.282 96.5338 104.317 96.5687C104.352 96.6035 104.399 96.6236 104.448 96.6249Z" fill="#F0F0F0"/>
<path d="M61.9632 99.2115L54.7248 38.7363H50.3184L57.5568 99.2115H61.9632Z" fill="white"/>
<path d="M32.1216 99.2066L32.1216 38.7314H31.7904L31.7904 99.2066H32.1216Z" fill="#E6E6E6"/>
<path opacity="0.6" d="M15 42H99.7395L100 38H15.2605L15 42Z" fill="#EBEBEB"/>
<path opacity="0.6" d="M15 49H99.7395L100 45H15.2605L15 49Z" fill="#EBEBEB"/>
<path opacity="0.6" d="M15 56H99.7395L100 52H15.2605L15 56Z" fill="#EBEBEB"/>
<path opacity="0.6" d="M15 63H99.7395L100 59H15.2605L15 63Z" fill="#EBEBEB"/>
<path opacity="0.6" d="M15 70H99.7395L100 65H15.2605L15 70Z" fill="#EBEBEB"/>
<path opacity="0.6" d="M15 77H99.7395L100 72H15.2605L15 77Z" fill="#EBEBEB"/>
<path d="M205 46H120V103H205V46Z" fill="#F5F5F5"/>
<path d="M205 103H207V46H205V103Z" fill="#E0E0E0"/>
<path d="M153.01 57.6338H137.41V81.3794H153.01V57.6338Z" fill="white"/>
<path d="M159.557 90H142L142.443 64H160L159.557 90Z" fill="white"/>
<path d="M195 51H178V77H195V51Z" fill="white"/>
<path d="M179.924 96H164L165.076 72H181L179.924 96Z" fill="white"/>
<path d="M170 119C170 119 170.395 130 185.5 130C200.605 130 201 119 201 119H170Z" fill="#F0F0F0"/>
<path d="M192 189H214V135H192V189Z" fill="#F0F0F0"/>
<path d="M101 194H210V189H101V194Z" fill="#F0F0F0"/>
<path d="M192 135H97V189H192V135Z" fill="#F5F5F5"/>
<path d="M184 141H105V158H184V141Z" fill="#F0F0F0"/>
<path d="M184 163H105V179H184V163Z" fill="#F0F0F0"/>
<path d="M139.752 138.053H156.706C157.138 138.052 157.553 137.879 157.858 137.574C158.164 137.268 158.336 136.854 158.338 136.421C158.336 135.989 158.164 135.574 157.858 135.268C157.553 134.963 157.138 134.79 156.706 134.789H139.752C139.32 134.79 138.905 134.963 138.6 135.268C138.294 135.574 138.121 135.989 138.12 136.421C138.121 136.854 138.294 137.268 138.6 137.574C138.905 137.879 139.32 138.052 139.752 138.053Z" fill="#F5F5F5"/>
<path d="M139.752 157.637H156.706C157.138 157.636 157.553 157.463 157.858 157.158C158.164 156.852 158.336 156.438 158.338 156.005C158.336 155.573 158.164 155.158 157.858 154.852C157.553 154.547 157.138 154.374 156.706 154.373H139.752C139.32 154.374 138.905 154.547 138.6 154.852C138.294 155.158 138.121 155.573 138.12 156.005C138.121 156.438 138.294 156.852 138.6 157.158C138.905 157.463 139.32 157.636 139.752 157.637Z" fill="#F5F5F5"/>
<path d="M94 135H192V130H94V135Z" fill="#F0F0F0"/>
<path d="M218 130H192V135H218V130Z" fill="#E6E6E6"/>
<path d="M49 128H18V194H49V128Z" fill="#F5F5F5"/>
<path d="M45 131H21V132H45V131Z" fill="#E6E6E6"/>
<path d="M81 128H50V194H81V128Z" fill="#F5F5F5"/>
<path d="M89.4528 183.553H92.3376L92.3376 122.698H89.4528L89.4528 183.553Z" fill="#E0E0E0"/>
<path d="M53 190L53 131H52L52 190H53Z" fill="#E6E6E6"/>
<path d="M60.5183 183.553L60.5183 123.207H60.0143L60.0143 183.553H60.5183Z" fill="#E6E6E6"/>
<path d="M77 131H52V132H77V131Z" fill="#E6E6E6"/>
<path d="M47.5 132C47.6322 132.001 47.7588 132.059 47.8523 132.162C47.9458 132.264 47.9988 132.403 48 132.548V141.452C47.9988 141.597 47.9458 141.736 47.8523 141.838C47.7588 141.941 47.6322 141.999 47.5 142C47.3677 141.999 47.2412 141.941 47.1477 141.838C47.0542 141.736 47.0012 141.597 47 141.452V132.548C47.0012 132.403 47.0542 132.264 47.1477 132.162C47.2412 132.059 47.3677 132.001 47.5 132Z" fill="#E0E0E0"/>
<path d="M51.5 132C51.6326 132 51.7598 132.058 51.8535 132.16C51.9473 132.263 52 132.402 52 132.548V141.452C52 141.598 51.9473 141.737 51.8535 141.84C51.7598 141.942 51.6326 142 51.5 142C51.3677 141.999 51.2412 141.941 51.1477 141.838C51.0542 141.736 51.0012 141.597 51 141.452V132.548C51.0012 132.403 51.0542 132.264 51.1477 132.162C51.2412 132.059 51.3677 132.001 51.5 132Z" fill="#E0E0E0"/>
<path d="M113.912 217C169.557 217 214.667 214.367 214.667 211.118C214.667 207.869 169.557 205.235 113.912 205.235C58.2666 205.235 13.1572 207.869 13.1572 211.118C13.1572 214.367 58.2666 217 113.912 217Z" fill="#F5F5F5"/>
<path d="M142.839 130.773C144.644 139.269 134.964 142.863 130.943 139.118C126.921 135.374 127.12 123.926 121.696 123.623C129.524 117.563 141.081 122.507 142.839 130.773Z" fill="#263238"/>
<path d="M134.943 183.779C134.722 183.779 134.508 183.698 134.342 183.551C134.177 183.403 134.071 183.201 134.046 182.981C132.043 166.13 134.567 125.877 155.21 108.999C155.301 108.916 155.408 108.853 155.525 108.812C155.641 108.772 155.765 108.756 155.888 108.765C156.011 108.774 156.13 108.808 156.24 108.865C156.349 108.922 156.446 109 156.524 109.096C156.602 109.191 156.66 109.301 156.694 109.42C156.728 109.538 156.737 109.662 156.722 109.785C156.706 109.907 156.666 110.025 156.603 110.131C156.541 110.237 156.457 110.329 156.358 110.402C136.309 126.8 133.879 166.239 135.845 182.767C135.872 183.005 135.803 183.244 135.655 183.433C135.507 183.621 135.29 183.743 135.052 183.773L134.943 183.779Z" fill="#407BFF"/>
<path d="M127.589 205.413H142.287C142.78 205.413 143.258 205.245 143.643 204.937C144.028 204.629 144.296 204.199 144.404 203.718L150.537 176.529C150.609 176.211 150.607 175.881 150.534 175.564C150.46 175.246 150.316 174.949 150.112 174.695C149.909 174.441 149.65 174.235 149.356 174.094C149.063 173.953 148.741 173.88 148.415 173.88H121.461C121.135 173.88 120.814 173.953 120.52 174.094C120.226 174.235 119.968 174.44 119.765 174.695C119.561 174.949 119.418 175.247 119.345 175.564C119.272 175.882 119.272 176.212 119.344 176.529L125.472 203.723C125.582 204.202 125.851 204.631 126.235 204.938C126.62 205.245 127.097 205.412 127.589 205.413Z" fill="#407BFF"/>
<path opacity="0.6" d="M127.589 205.413H142.287C142.78 205.413 143.258 205.245 143.643 204.937C144.028 204.629 144.296 204.199 144.404 203.718L150.537 176.529C150.609 176.211 150.607 175.881 150.534 175.564C150.46 175.246 150.316 174.949 150.112 174.695C149.909 174.441 149.65 174.235 149.356 174.094C149.063 173.953 148.741 173.88 148.415 173.88H121.461C121.135 173.88 120.814 173.953 120.52 174.094C120.226 174.235 119.968 174.44 119.765 174.695C119.561 174.949 119.418 175.247 119.345 175.564C119.272 175.882 119.272 176.212 119.344 176.529L125.472 203.723C125.582 204.202 125.851 204.631 126.235 204.938C126.62 205.245 127.097 205.412 127.589 205.413Z" fill="white"/>
<path d="M141.201 132.468C141.131 132.467 141.061 132.458 140.992 132.442C140.877 132.415 140.768 132.366 140.672 132.297C140.575 132.228 140.494 132.14 140.431 132.039C140.369 131.939 140.327 131.827 140.307 131.71C140.288 131.593 140.292 131.473 140.32 131.358C140.32 131.227 143.266 118.402 138.786 110.866C138.664 110.659 138.629 110.411 138.689 110.178C138.749 109.944 138.899 109.745 139.107 109.622C139.314 109.5 139.562 109.465 139.795 109.525C140.029 109.585 140.228 109.735 140.351 109.943C145.222 118.105 142.244 131.227 142.103 131.749C142.06 131.955 141.946 132.139 141.781 132.271C141.617 132.402 141.411 132.472 141.201 132.468Z" fill="#407BFF"/>
<path d="M148.827 105.859C148.462 114.048 166.236 117.954 171.931 114.491C177.627 111.028 173.606 99.8876 182.811 99.8042C167.42 93.5717 149.181 97.8901 148.827 105.859Z" fill="#407BFF"/>
<path d="M144.523 107.054C147.903 113.724 138.593 117.667 133.821 115.06C129.049 112.452 127.156 103.179 121.544 103.518C128.485 97.7964 141.232 100.561 144.523 107.054Z" fill="#407BFF"/>
<path d="M134.421 170.004H134.385C134.146 169.993 133.92 169.889 133.757 169.713C133.594 169.538 133.507 169.305 133.514 169.065C133.514 168.93 133.915 155.422 126.327 153.789C126.093 153.739 125.888 153.597 125.758 153.396C125.628 153.194 125.583 152.949 125.633 152.715C125.684 152.48 125.825 152.276 126.027 152.146C126.228 152.015 126.473 151.971 126.708 152.021C135.777 153.967 135.35 168.513 135.324 169.133C135.316 169.367 135.217 169.589 135.049 169.751C134.88 169.914 134.655 170.004 134.421 170.004Z" fill="#407BFF"/>
<path d="M131.866 150.68C134.223 155.051 126.994 160.376 123.422 159.547C119.849 158.717 118.728 151.922 114.436 153.559C119.933 147.395 129.571 146.43 131.866 150.68Z" fill="#407BFF"/>
<path d="M135.354 157.226C135.289 157.234 135.222 157.234 135.156 157.226C134.923 157.173 134.72 157.03 134.591 156.828C134.462 156.626 134.418 156.381 134.468 156.147C134.603 155.531 137.91 141.048 147.788 137.194C147.9 137.144 148.021 137.118 148.144 137.116C148.267 137.115 148.388 137.138 148.502 137.185C148.615 137.232 148.718 137.301 148.804 137.389C148.889 137.476 148.957 137.58 149.001 137.695C149.045 137.809 149.066 137.931 149.062 138.054C149.058 138.176 149.029 138.297 148.977 138.408C148.925 138.519 148.851 138.619 148.76 138.7C148.669 138.782 148.561 138.844 148.445 138.884C139.459 142.388 136.267 156.397 136.236 156.538C136.188 156.735 136.075 156.91 135.915 157.035C135.755 157.16 135.557 157.228 135.354 157.226Z" fill="#407BFF"/>
<path d="M146.203 132.438C139.084 136.161 143.888 144.12 149.437 144.433C154.986 144.746 162.632 138.128 166.893 141.267C165.151 133.063 153.14 128.813 146.203 132.438Z" fill="#407BFF"/>
<path d="M87.3613 101.025C87.1344 100.857 86.9834 100.605 86.9413 100.326C86.8993 100.046 86.9697 99.7614 87.1371 99.5337L132.021 38.471C132.19 38.244 132.441 38.093 132.721 38.0509C133 38.0089 133.285 38.0793 133.513 38.2467C133.74 38.4152 133.891 38.6667 133.933 38.9462C133.975 39.2257 133.905 39.5105 133.737 39.7383L88.853 100.796C88.7722 100.911 88.6688 101.01 88.5491 101.084C88.4293 101.159 88.2957 101.208 88.1562 101.23C88.0168 101.251 87.8745 101.244 87.7378 101.209C87.6012 101.174 87.4731 101.111 87.3613 101.025Z" fill="#407BFF"/>
<path d="M155.452 65.5007L153.788 67.7861L154.219 68.1L155.883 65.8145L155.452 65.5007Z" fill="#407BFF"/>
<path d="M157.666 64.4702L157.233 64.1572C157.764 63.4331 157.986 62.5283 157.851 61.6409C157.716 60.7534 157.235 59.9555 156.514 59.4216L145.081 51.0195L145.394 50.5918L156.847 58.9783C157.262 59.2848 157.613 59.67 157.878 60.1121C158.144 60.5541 158.32 61.0442 158.396 61.5544C158.472 62.0645 158.447 62.5846 158.321 63.0849C158.196 63.5853 157.974 64.056 157.666 64.4702Z" fill="#407BFF"/>
<path d="M138.593 83.8133L88.525 47.0182C88.3074 46.8583 88.1235 46.6571 87.9838 46.4262C87.844 46.1952 87.7512 45.9389 87.7105 45.6721C87.6698 45.4052 87.6821 45.1329 87.7467 44.8708C87.8113 44.6087 87.9269 44.3619 88.0869 44.1444L102.805 24.1223C102.964 23.9042 103.165 23.7198 103.396 23.5796C103.627 23.4395 103.884 23.3464 104.151 23.3056C104.418 23.2649 104.69 23.2774 104.952 23.3424C105.215 23.4073 105.461 23.5235 105.679 23.6842L155.747 60.4793C155.965 60.6387 156.148 60.8394 156.288 61.0699C156.428 61.3004 156.52 61.5562 156.561 61.8227C156.602 62.0891 156.59 62.361 156.525 62.6227C156.461 62.8844 156.345 63.1308 156.185 63.3478L141.467 83.3752C141.308 83.5933 141.107 83.7777 140.876 83.9179C140.645 84.058 140.388 84.1511 140.121 84.1918C139.854 84.2325 139.582 84.2201 139.32 84.1551C139.057 84.0901 138.811 83.974 138.593 83.8133Z" fill="white"/>
<path d="M139.782 84.4813C139.289 84.4806 138.809 84.3236 138.411 84.0328L88.3423 47.2324C88.0957 47.0528 87.8871 46.8261 87.7285 46.5654C87.57 46.3048 87.4646 46.0153 87.4186 45.7137C87.3725 45.4121 87.3866 45.1043 87.4601 44.8082C87.5336 44.5121 87.6651 44.2335 87.8468 43.9884L102.565 23.961C102.926 23.4623 103.471 23.1273 104.079 23.0295C104.687 22.9317 105.309 23.0792 105.809 23.4395L155.877 60.2398C156.371 60.605 156.701 61.1504 156.795 61.7575C156.843 62.0592 156.829 62.3674 156.756 62.6638C156.682 62.9602 156.55 63.239 156.368 63.4838L141.65 83.5112C141.284 84.0052 140.739 84.3351 140.132 84.4292C140.017 84.454 139.9 84.4714 139.782 84.4813ZM138.739 83.5999C139.115 83.8742 139.582 83.9907 140.043 83.9244C140.503 83.8581 140.919 83.6143 141.201 83.2452L155.924 63.2178C156.204 62.8367 156.322 62.36 156.25 61.8925C156.179 61.425 155.925 61.005 155.544 60.7248L105.475 23.9245C105.287 23.7852 105.073 23.6845 104.845 23.6282C104.618 23.572 104.381 23.5612 104.149 23.5966C103.918 23.632 103.695 23.7128 103.495 23.8344C103.295 23.956 103.12 24.116 102.982 24.3052L88.2589 44.3326C87.9786 44.7134 87.8604 45.1897 87.9303 45.6574C87.9648 45.8895 88.0453 46.1124 88.1671 46.313C88.2889 46.5136 88.4495 46.6879 88.6396 46.8256L138.739 83.5999Z" fill="#407BFF"/>
<path d="M98.1062 38.6166L100.568 40.1812C100.361 40.5121 100.246 40.8918 100.234 41.2817C100.245 41.5395 100.317 41.791 100.445 42.0151C100.573 42.2392 100.753 42.4295 100.969 42.5699C101.248 42.8031 101.602 42.9272 101.966 42.9194C102.104 42.9154 102.239 42.8806 102.362 42.8174C102.484 42.7542 102.592 42.6643 102.675 42.5543C102.751 42.4457 102.803 42.3217 102.826 42.1911C102.85 42.0605 102.844 41.9264 102.811 41.798C102.608 41.2722 102.293 40.7969 101.887 40.4055C101.151 39.6507 100.601 38.7346 100.281 37.73C100.191 37.3717 100.184 36.9979 100.26 36.6365C100.336 36.2752 100.493 35.9357 100.719 35.6438C101.027 35.2314 101.45 34.9193 101.934 34.7467C102.492 34.5706 103.091 34.5688 103.65 34.7415C104.451 34.9655 105.199 35.3506 105.846 35.8733C106.704 36.421 107.374 37.2181 107.765 38.1576C107.888 38.5797 107.918 39.0235 107.853 39.4583C107.789 39.8931 107.63 40.3088 107.39 40.6767L104.959 39.1121C105.062 38.9584 105.132 38.7853 105.165 38.6035C105.198 38.4217 105.193 38.2351 105.151 38.0552C105.109 37.8754 105.03 37.706 104.92 37.5577C104.81 37.4093 104.671 37.2851 104.511 37.1928C104.291 37.0046 104.012 36.8994 103.723 36.8955C103.62 36.9009 103.52 36.9295 103.43 36.9791C103.339 37.0288 103.261 37.0983 103.202 37.1823C103.152 37.2498 103.12 37.3281 103.107 37.4107C103.095 37.4933 103.102 37.5778 103.129 37.6569C103.262 37.9998 103.47 38.3083 103.739 38.5592C104.434 39.2896 105.04 40.1002 105.543 40.974C105.817 41.4727 105.944 42.0387 105.909 42.6064C105.861 43.1208 105.671 43.6118 105.361 44.025C104.993 44.5327 104.48 44.9179 103.89 45.1307C103.279 45.3556 102.614 45.3938 101.981 45.2402C101.188 45.0373 100.447 44.6694 99.8064 44.1606C98.4452 43.1645 97.7202 42.21 97.5585 41.3026C97.4072 40.3704 97.602 39.4151 98.1062 38.6166Z" fill="#E3001B"/>
<path d="M107.3 43.2846C107.654 42.6875 108.135 42.1761 108.71 41.7873C109.285 41.3985 109.939 41.142 110.625 41.0363C111.31 40.9305 112.011 40.9782 112.676 41.1757C113.342 41.3733 113.955 41.716 114.472 42.179C115.803 43.1595 116.567 44.2704 116.761 45.5117C116.841 46.1882 116.781 46.8739 116.587 47.5268C116.392 48.1797 116.067 48.7859 115.629 49.3085C115.078 50.1252 114.337 50.7963 113.47 51.2643C112.73 51.6279 111.899 51.764 111.082 51.6555C110.129 51.5094 109.231 51.1196 108.474 50.5237C107.684 49.983 107.028 49.2696 106.555 48.4375C106.169 47.6932 106.016 46.8499 106.116 46.0176C106.257 45.0164 106.666 44.072 107.3 43.2846ZM109.762 45.1101C109.252 45.6884 108.959 46.4258 108.933 47.1963C108.965 47.4804 109.055 47.7549 109.197 48.0029C109.339 48.2508 109.531 48.4668 109.761 48.6375C109.99 48.8082 110.252 48.9299 110.53 48.9951C110.809 49.0603 111.098 49.0676 111.379 49.0165C111.927 48.8861 112.526 48.3697 113.183 47.4518C113.666 46.9015 113.934 46.1963 113.94 45.4647C113.913 45.1782 113.824 44.9012 113.676 44.6539C113.529 44.4067 113.329 44.1954 113.09 44.0357C112.871 43.8652 112.617 43.7455 112.347 43.685C112.076 43.6246 111.795 43.625 111.525 43.6862C110.783 43.9376 110.154 44.4416 109.746 45.1101H109.762Z" fill="#E3001B"/>
<path d="M113.997 54.3716L119.875 46.3711L123.99 49.4013C124.596 49.8116 125.138 50.311 125.596 50.8825C125.902 51.3005 126.058 51.8095 126.039 52.3272C126 52.8588 125.821 53.3707 125.521 53.8112C125.221 54.2517 124.809 54.6051 124.329 54.8358C123.902 55.0206 123.435 55.0925 122.973 55.0444C122.592 54.9945 122.221 54.8872 121.872 54.7263C122.052 54.9591 122.196 55.2175 122.3 55.4929C122.356 55.7252 122.386 55.9632 122.389 56.2022C122.41 56.4521 122.41 56.7034 122.389 56.9533L121.867 60.1504L119.077 58.1007L119.551 54.6845C119.616 54.3732 119.616 54.0519 119.551 53.7406C119.464 53.4869 119.3 53.2665 119.082 53.1095L118.863 52.9478L116.479 56.1918L113.997 54.3716ZM119.974 51.4301L121.017 52.1968C121.25 52.345 121.496 52.4707 121.752 52.5723C121.936 52.6563 122.143 52.6711 122.336 52.614C122.568 52.5407 122.765 52.3864 122.891 52.1794C123.018 51.9724 123.065 51.7267 123.025 51.4875C122.871 51.1172 122.6 50.8077 122.253 50.6061L121.168 49.8081L119.974 51.4301Z" fill="#E3001B"/>
<path d="M122.686 60.7561L128.564 52.7607L132.679 55.7857C133.285 56.1989 133.826 56.6998 134.285 57.2721C134.59 57.6909 134.746 58.1993 134.729 58.7168C134.7 59.2862 134.496 59.8328 134.144 60.2814C133.862 60.6913 133.472 61.016 133.018 61.2202C132.591 61.4026 132.124 61.4744 131.662 61.4289C131.28 61.3792 130.909 61.2701 130.561 61.1055C130.739 61.3397 130.883 61.5978 130.989 61.8722C131.045 62.1063 131.075 62.3459 131.078 62.5867C131.099 62.8366 131.099 63.0878 131.078 63.3377L130.556 66.5348L127.761 64.4695L128.235 61.0586C128.3 60.7454 128.3 60.4225 128.235 60.1093C128.146 59.8565 127.982 59.6365 127.766 59.4783L127.547 59.3218L125.163 62.5658L122.686 60.7561ZM128.684 57.825L129.727 58.5916C129.959 58.7396 130.205 58.8637 130.462 58.9619C130.644 59.0501 130.853 59.0668 131.046 59.0089C131.245 58.9493 131.419 58.8267 131.542 58.6594C131.63 58.5527 131.694 58.4274 131.727 58.2929C131.76 58.1584 131.763 58.018 131.735 57.8823C131.581 57.5104 131.31 57.199 130.963 56.9957L129.878 56.1977L128.684 57.825Z" fill="#E3001B"/>
<path d="M135.934 58.1797L138.677 60.1929L138.302 64.0784L141.9 62.5659L144.633 64.5739L138.109 66.9365L135.647 70.2848L133.17 68.4646L135.631 65.1163L135.934 58.1797Z" fill="#E3001B"/>
<path d="M145.285 65.0537L147.658 66.7957L146.318 68.6211L143.189 71.9433L141.723 70.8637L143.966 66.8739L145.285 65.0537ZM140.93 71.1506L143.142 72.7778L141.723 74.7388L139.512 73.1116L140.93 71.1506Z" fill="#E3001B"/>
<path d="M100.755 64.6471C100.959 65.0852 101.094 65.3981 101.245 65.7632C101.397 66.1282 101.527 66.4673 101.663 66.8063C101.934 67.5051 102.184 68.2144 102.393 68.9237C102.838 70.3459 103.207 71.7909 103.498 73.2526C103.655 73.9827 103.775 74.7233 103.895 75.4639C104.015 76.2045 104.093 76.9608 104.15 77.717C104.285 79.243 104.318 80.7763 104.249 82.3066V82.5674C104.244 82.7348 104.22 82.9011 104.176 83.0629C103.906 84.0958 103.458 85.0736 102.852 85.9523C102.38 86.6456 101.857 87.3029 101.287 87.9185C100.274 89.0262 99.1768 90.0549 98.0066 90.9956C96.88 91.9083 95.7274 92.7532 94.5487 93.5408C93.3692 94.3316 92.1507 95.0626 90.8979 95.7313C90.4801 95.9555 89.9962 96.0239 89.5327 95.9241C89.0692 95.8243 88.6563 95.5629 88.3678 95.1866C88.0794 94.8104 87.9341 94.3438 87.9581 93.8702C87.9821 93.3967 88.1737 92.9472 88.4988 92.602L88.5405 92.555L91.2421 89.6396C92.1339 88.6591 93.0101 87.6838 93.8498 86.6981C94.6495 85.7602 95.3981 84.78 96.0925 83.7618C96.392 83.3259 96.6571 82.8672 96.8852 82.3901C97.033 82.1283 97.1253 81.8389 97.1564 81.54L97.0782 82.3014C96.9739 81.0914 96.8487 79.8814 96.6245 78.6506C96.4002 77.4197 96.1499 76.2098 95.8265 74.9998C95.5031 73.7898 95.1589 72.5641 94.7834 71.3489C94.5852 70.7492 94.3714 70.1546 94.168 69.5652L93.8446 68.6943L93.5421 67.9015L93.4639 67.6981C93.1087 66.7356 93.1384 65.6732 93.5469 64.7321C93.9554 63.791 94.7111 63.0437 95.6568 62.6458C96.6024 62.248 97.6651 62.2302 98.6235 62.5961C99.5819 62.9621 100.362 63.6837 100.802 64.6106L100.755 64.6471Z" fill="#B55B52"/>
<path d="M74.8287 34.8459C71.4543 38.0794 71.8767 53.8875 73.264 68.929C74.6513 83.9704 87.3927 81.4826 92.1232 74.9633C98.3817 66.2951 91.3408 53.3191 92.3526 48.2079C93.4531 42.6534 94.2772 36.3688 87.6118 32.5615C80.3727 28.4152 74.0985 31.5445 74.8287 34.8459Z" fill="#263238"/>
<path d="M72.4507 70.358H72.4246C72.3493 70.3501 72.2802 70.3128 72.2324 70.2542C72.1845 70.1956 72.1617 70.1204 72.169 70.0451C72.5915 65.8727 72.2055 60.4434 71.8352 55.1967C71.1364 45.3394 70.474 36.0246 74.9019 34.5747C74.9375 34.5625 74.9752 34.5576 75.0127 34.5603C75.0502 34.5629 75.0868 34.573 75.1204 34.59C75.1539 34.607 75.1837 34.6306 75.208 34.6593C75.2323 34.688 75.2506 34.7213 75.2618 34.7572C75.2849 34.8292 75.2787 34.9073 75.2445 34.9747C75.2103 35.0421 75.151 35.0932 75.0793 35.1171C71.079 36.4262 71.7518 45.9496 72.4037 55.1601C72.7792 60.4278 73.1652 65.8727 72.7375 70.1025C72.7298 70.173 72.6962 70.2382 72.6432 70.2854C72.5902 70.3326 72.5216 70.3584 72.4507 70.358Z" fill="#263238"/>
<path d="M78.6989 66.989L77.4106 70.6399C76.9777 71.8759 76.5709 73.112 76.1693 74.3428C75.7678 75.5737 75.4027 76.8045 75.0689 77.9937C74.7351 79.1828 74.4535 80.4084 74.2501 81.5558V80.9613C74.2249 81.6075 74.3114 82.2533 74.5056 82.8701C74.6204 83.2196 74.756 83.5794 74.9176 83.9549C75.0793 84.3305 75.2932 84.6903 75.5122 85.0711C75.9786 85.8573 76.5015 86.6085 77.0768 87.3189C77.3741 87.6892 77.6871 88.0595 78.0156 88.4194L78.9701 89.4625L79.1787 89.6867C79.4805 90.0209 79.6598 90.4477 79.6873 90.8972C79.7148 91.3466 79.5889 91.7921 79.3301 92.1606C79.0713 92.5291 78.695 92.7988 78.2629 92.9254C77.8308 93.0521 77.3684 93.0282 76.9517 92.8577C76.6074 92.7117 76.3571 92.6126 76.0755 92.4822C75.7938 92.3519 75.5539 92.2267 75.2671 92.0859C74.742 91.8077 74.2205 91.5052 73.7024 91.1784C72.6661 90.505 71.7014 89.7273 70.8235 88.8575C69.8736 87.9101 69.0592 86.836 68.4035 85.6656C68.0584 85.0243 67.7654 84.3563 67.5273 83.6681C67.2794 82.9379 67.1047 82.1849 67.0058 81.4202C66.9981 81.3335 66.9981 81.2462 67.0058 81.1595V80.8257C67.2171 77.8823 67.7167 74.9668 68.4974 72.121C68.852 70.7389 69.2745 69.3829 69.7126 68.0373C70.1507 66.6917 70.6253 65.3983 71.2042 64.0214C71.5983 63.0563 72.3549 62.284 73.3117 61.8703C74.2686 61.4565 75.3495 61.4342 76.3226 61.8082C77.2957 62.1821 78.0834 62.9225 78.517 63.8706C78.9505 64.8187 78.9952 65.8989 78.6415 66.8795L78.6989 66.989Z" fill="#B55B52"/>
<path d="M77.0509 59.1396C72.8785 59.9272 72.0075 61.6274 68.946 67.9434C69.8692 72.1157 75.6792 75.7666 75.6792 75.7666C75.6792 75.7666 83.8936 64.5168 77.0509 59.1396Z" fill="#407BFF"/>
<path d="M70.4373 67.0093C70.3695 69.2259 70.5886 71.9067 71.084 77.1795C71.423 81.6492 70.3069 90.1452 71.7151 90.7397H94.2616C94.7153 84.3403 94.5484 79.9333 98.5956 65.6481C98.8343 64.9011 98.9029 64.1103 98.7963 63.3334C98.6897 62.5565 98.4106 61.8133 97.9796 61.1582C97.5485 60.5032 96.9764 59.9528 96.3051 59.5475C95.6338 59.1422 94.8803 58.8922 94.0999 58.8158C93.2028 58.7272 92.2588 58.6489 91.2836 58.602C87.877 58.3592 84.4574 58.3592 81.0508 58.602C79.9399 58.7063 78.7873 58.8471 77.6816 58.9984C75.7321 59.2856 73.9432 60.242 72.6215 61.7035C71.2998 63.1651 70.5276 65.0409 70.4373 67.0093Z" fill="#407BFF"/>
<path d="M80.7012 64.1198C80.5864 64.5423 80.3361 64.8396 80.1796 64.7874C80.0232 64.7352 79.9397 64.3545 80.0597 63.9321C80.1796 63.5096 80.4195 63.2175 80.6073 63.2697C80.7951 63.3218 80.8263 63.7026 80.7012 64.1198Z" fill="white"/>
<path d="M79.8781 67.5989C79.8208 68.0318 79.6226 68.3656 79.4348 68.3395C79.2471 68.3134 79.1323 67.9483 79.1845 67.5155C79.2366 67.0826 79.4348 66.7488 79.6278 66.7749C79.8208 66.8009 79.9303 67.1712 79.8781 67.5989Z" fill="white"/>
<path d="M89.1136 47.4619C89.0145 50.8676 88.592 56.4325 90.7199 58.6282C90.7199 58.6282 87.5907 58.1641 80.9774 62.2791C79.0582 59.5253 80.482 58.6282 80.482 58.6282C82.37 58.284 84.0181 56.3647 82.9854 53.9813L89.1136 47.4619Z" fill="#B55B52"/>
<path d="M82.3708 61.4334C84.5509 61.7411 85.2341 65.0373 85.2341 65.0373C85.2341 65.0373 90.4496 61.3291 92.1342 58.7162C91.4753 58.3451 90.8363 57.9394 90.2201 57.501C90.2201 57.501 83.6434 60.1713 82.3708 61.4334Z" fill="white"/>
<path d="M80.2946 61.0794C80.2946 61.0794 78.563 61.4549 77.6868 63.8645C77.1653 59.061 79.4653 58.6177 79.4653 58.6177C80.0658 58.2855 80.7673 58.1852 81.4367 58.3361C80.957 58.5891 80.5804 59.0014 80.3719 59.5022C80.1634 60.0029 80.1361 60.5607 80.2946 61.0794Z" fill="white"/>
<path opacity="0.2" d="M85.2811 51.953L84.4206 52.4745L82.9863 53.9974C83.3572 54.7744 83.4078 55.666 83.1271 56.48C84.7596 55.8437 86.4077 54.5659 86.8458 53.1838V53.1525C86.9142 52.9467 86.9168 52.7246 86.8531 52.5173C86.7895 52.3099 86.6628 52.1275 86.4906 51.9956C86.3185 51.8636 86.1095 51.7886 85.8927 51.7809C85.6759 51.7733 85.4621 51.8334 85.2811 51.953Z" fill="black"/>
<path d="M88.0027 39.4776C89.5673 44.6514 90.3496 46.7793 88.8371 50.4666C86.5684 55.9794 80.0699 57.0851 77.0293 52.5528C74.2964 48.4743 72.1789 40.6929 76.2261 36.6039C80.2733 32.515 86.438 34.2987 88.0027 39.4776Z" fill="#B55B52"/>
<path d="M80.9622 44.1607C81.1135 44.5884 81.0196 45.0265 80.7536 45.1412C80.4876 45.256 80.1486 45.0004 79.9973 44.5727C79.8461 44.1451 79.94 43.7017 80.206 43.587C80.472 43.4723 80.811 43.7278 80.9622 44.1607Z" fill="#263238"/>
<path d="M76.0083 46.387C76.1595 46.8146 76.0656 47.2579 75.7996 47.3727C75.5337 47.4874 75.1999 47.2319 75.0486 46.799C74.8974 46.3661 74.9912 45.9332 75.2572 45.8185C75.5232 45.7037 75.8466 45.9593 76.0083 46.387Z" fill="#263238"/>
<path d="M75.3713 45.793L74.2917 45.9129C74.2917 45.9129 75.0741 46.5023 75.3713 45.793Z" fill="#263238"/>
<path d="M77.4572 46.1426C77.4382 47.2799 77.2753 48.4102 76.9722 49.5066C77.6971 49.8038 78.5368 49.1676 78.5368 49.1676L77.4572 46.1426Z" fill="#A02724"/>
<path d="M80.8687 49.2296C81.1145 49.1812 81.3641 49.155 81.6146 49.1514C81.6314 49.1514 81.6481 49.1548 81.6637 49.1614C81.6792 49.168 81.6933 49.1777 81.705 49.1899C81.7166 49.202 81.7258 49.2165 81.7317 49.2323C81.7377 49.248 81.7404 49.2649 81.7397 49.2818C81.7372 49.3178 81.7214 49.3516 81.6953 49.3766C81.6693 49.4016 81.635 49.4162 81.5989 49.4174C81.0338 49.4247 80.4775 49.5587 79.9711 49.8097C79.4647 50.0606 79.0211 50.422 78.673 50.8673C78.6638 50.881 78.6519 50.8927 78.6381 50.9018C78.6244 50.9109 78.6089 50.9172 78.5927 50.9204C78.5765 50.9235 78.5598 50.9235 78.5436 50.9202C78.5274 50.9169 78.512 50.9104 78.4983 50.9012C78.4846 50.8919 78.4729 50.8801 78.4637 50.8663C78.4546 50.8525 78.4483 50.837 78.4452 50.8208C78.442 50.8046 78.4421 50.7879 78.4454 50.7717C78.4487 50.7555 78.4552 50.7401 78.4644 50.7264C79.0521 49.9462 79.9092 49.4126 80.8687 49.2296Z" fill="#263238"/>
<path d="M90.6897 42.8676C90.7204 43.3829 90.6436 43.899 90.464 44.383C90.2844 44.867 90.006 45.3082 89.6466 45.6787C88.713 46.5497 87.5604 45.5484 87.2631 44.1141C86.9919 42.8102 87.1536 40.7919 88.3636 40.4946C89.5736 40.1973 90.6532 41.4751 90.6897 42.8676Z" fill="#FFB573"/>
<path d="M83.8309 34.376C79.9193 38.5796 85.166 48.5308 84.6288 55.6864C83.9769 64.4588 89.3541 65.747 92.7389 58.7218C90.2198 52.0773 92.8223 42.1262 92.1443 38.637C91.3568 34.6367 86.6107 32.4254 83.8309 34.376Z" fill="#263238"/>
<path d="M78.7667 40.6604C78.8011 40.6558 78.8361 40.6585 78.8693 40.6684C78.9026 40.6783 78.9334 40.6951 78.9597 40.7178C79.1954 40.9365 79.482 41.093 79.7934 41.1731C80.1048 41.2532 80.4313 41.2545 80.7433 41.1767C80.7752 41.1664 80.8088 41.1627 80.8421 41.1659C80.8754 41.1691 80.9077 41.179 80.937 41.1952C80.9663 41.2113 80.992 41.2332 81.0125 41.2597C81.0331 41.2861 81.0479 41.3164 81.0563 41.3488C81.0718 41.4185 81.0613 41.4915 81.0267 41.554C80.9921 41.6164 80.9358 41.6641 80.8685 41.6879C80.4747 41.792 80.061 41.7955 79.6655 41.6979C79.27 41.6004 78.9053 41.4051 78.605 41.1298C78.5685 41.0932 78.543 41.047 78.5314 40.9966C78.5199 40.9462 78.5226 40.8936 78.5395 40.8447C78.5563 40.7958 78.5865 40.7526 78.6267 40.72C78.6669 40.6875 78.7154 40.6668 78.7667 40.6604Z" fill="#263238"/>
<path d="M73.6188 44.9479C73.5882 44.9607 73.5554 44.9672 73.5223 44.9672C73.4892 44.9672 73.4564 44.9607 73.4258 44.9479C73.3619 44.9202 73.3112 44.869 73.284 44.805C73.2568 44.7409 73.2553 44.6688 73.2798 44.6037C73.4138 44.1981 73.6392 43.8287 73.9385 43.524C74.2378 43.2193 74.603 42.9873 75.0061 42.8461C75.0387 42.8368 75.0729 42.8345 75.1065 42.8394C75.14 42.8444 75.1721 42.8563 75.2007 42.8746C75.2293 42.8928 75.2537 42.9169 75.2723 42.9452C75.2909 42.9736 75.3033 43.0055 75.3086 43.039C75.3245 43.1088 75.3128 43.182 75.2758 43.2433C75.2388 43.3046 75.1795 43.3491 75.1104 43.3676C74.7937 43.4854 74.5077 43.6732 74.2738 43.9171C74.0399 44.1609 73.8641 44.4545 73.7596 44.7758C73.7493 44.8127 73.7315 44.847 73.7073 44.8766C73.683 44.9062 73.6529 44.9305 73.6188 44.9479Z" fill="#263238"/>
<path d="M80.3311 43.5605L79.2515 43.6857C79.2515 43.6857 80.0338 44.2751 80.3311 43.5605Z" fill="#263238"/>
<path d="M94.3036 58.8682C97.9544 59.4367 99.4408 60.5528 102.44 67.9274C101.079 70.676 94.9868 77.6386 94.9868 77.6386C94.9868 77.6386 89.714 64.2401 94.3036 58.8682Z" fill="#407BFF"/>
<path d="M98.0795 201.637C100.26 198.158 100.359 197.198 101.777 195.378C102.987 193.814 102.992 193.933 104.479 192.588C105.465 191.696 104.651 190.418 103.577 189.876C102.737 189.459 101.824 189.088 100.812 188.53L96.9425 192.04C97.6153 194.038 97.2398 198.195 95.769 200.954C94.7207 202.92 97.2085 203.024 98.0795 201.637Z" fill="#263238"/>
<path d="M71.7153 90.7656C71.7153 90.7656 71.1937 118.882 70.5522 138.065C70.4315 141.91 71.2665 145.725 72.9826 149.168C79.7628 162.781 96.6609 192.368 96.6609 192.368C97.3389 192.963 100.572 189.479 100.833 188.519C100.833 188.519 91.6436 151.129 83.497 142.753C94.3244 112.791 91.3828 90.7656 91.3828 90.7656H71.7153Z" fill="#263238"/>
<g opacity="0.2">
<path d="M91.4567 91.5898L80.8328 92.3409C80.8328 92.3409 82.5643 123.222 84.2541 140.537C93.0057 115.059 91.8426 95.7622 91.4567 91.5898Z" fill="black"/>
<path d="M84.6145 144.094C85.5637 152.96 86.5442 164.528 86.9875 175.199C90.4715 181.457 93.5955 186.97 95.301 189.969C96.1303 186.25 97.0169 181.812 97.7262 177.108C94.5865 166.228 89.5014 150.577 84.6145 144.094Z" fill="black"/>
</g>
<path d="M94.5849 190.908C94.9657 191.388 95.628 192.243 96.1183 192.901C96.1989 192.998 96.312 193.063 96.4367 193.083C96.5614 193.103 96.6892 193.077 96.7963 193.01C98.438 191.721 99.957 190.282 101.334 188.713C101.4 188.636 101.442 188.541 101.452 188.44C101.462 188.339 101.441 188.238 101.391 188.149L100.285 186.183C100.25 186.121 100.201 186.067 100.142 186.026C100.083 185.985 100.016 185.957 99.9456 185.945C99.875 185.932 99.8025 185.936 99.7333 185.954C99.664 185.973 99.5997 186.007 99.5448 186.053L94.6475 190.225C94.5985 190.266 94.5581 190.316 94.5284 190.372C94.4987 190.429 94.4805 190.49 94.4747 190.554C94.4689 190.617 94.4756 190.681 94.4945 190.742C94.5134 190.803 94.5442 190.859 94.5849 190.908Z" fill="#407BFF"/>
<path opacity="0.3" d="M94.5849 190.908C94.9657 191.388 95.628 192.243 96.1183 192.901C96.1989 192.998 96.312 193.063 96.4367 193.083C96.5614 193.103 96.6892 193.077 96.7963 193.01C98.438 191.721 99.957 190.282 101.334 188.713C101.4 188.636 101.442 188.541 101.452 188.44C101.462 188.339 101.441 188.238 101.391 188.149L100.285 186.183C100.25 186.121 100.201 186.067 100.142 186.026C100.083 185.985 100.016 185.957 99.9456 185.945C99.875 185.932 99.8025 185.936 99.7333 185.954C99.664 185.973 99.5997 186.007 99.5448 186.053L94.6475 190.225C94.5985 190.266 94.5581 190.316 94.5284 190.372C94.4987 190.429 94.4805 190.49 94.4747 190.554C94.4689 190.617 94.4756 190.681 94.4945 190.742C94.5134 190.803 94.5442 190.859 94.5849 190.908Z" fill="white"/>
<path d="M84.0394 205.408C88.2118 205.408 89.8077 205.34 92.1651 205.261C94.1783 205.194 94.6372 205.444 96.254 205.408C97.6048 205.376 97.6726 204.088 97.391 202.602C97.2502 201.856 97.0103 200.943 96.8329 199.738H90.4909C89.4791 201.381 85.7657 202.617 83.3823 202.93C81.1135 203.233 82.3757 205.408 84.0394 205.408Z" fill="#263238"/>
<path d="M77.6089 90.7666C77.6089 90.7666 84.1647 126.873 86.5691 143.224C89.0933 160.435 92.2018 188.306 90.4911 199.723C91.4194 200.291 94.8512 200.328 96.8331 199.723C96.8331 199.723 105.569 162.239 98.4864 143.985C103.248 103.764 96.202 101.667 94.2567 90.751L77.6089 90.7666Z" fill="#263238"/>
<path d="M90.23 197.36C90.1399 198.151 89.9953 198.935 89.7971 199.707C89.7765 199.768 89.7697 199.833 89.777 199.897C89.7844 199.961 89.8058 200.022 89.8396 200.077C89.8735 200.132 89.919 200.178 89.973 200.214C90.0269 200.249 90.0879 200.272 90.1517 200.281C92.4413 200.656 95.847 200.114 96.9318 199.926C97.0359 199.909 97.1324 199.861 97.2088 199.789C97.2851 199.716 97.3377 199.622 97.3595 199.519L97.9071 197.329C97.9271 197.261 97.9306 197.19 97.9175 197.12C97.9044 197.051 97.8749 196.986 97.8315 196.93C97.7882 196.875 97.7322 196.83 97.6683 196.801C97.6043 196.771 97.5342 196.757 97.4638 196.76L90.7515 196.891C90.6218 196.89 90.4964 196.938 90.4 197.024C90.3036 197.111 90.2429 197.231 90.23 197.36Z" fill="#407BFF"/>
<path opacity="0.3" d="M90.23 197.36C90.1399 198.151 89.9953 198.935 89.7971 199.707C89.7765 199.768 89.7697 199.833 89.777 199.897C89.7844 199.961 89.8058 200.022 89.8396 200.077C89.8735 200.132 89.919 200.178 89.973 200.214C90.0269 200.249 90.0879 200.272 90.1517 200.281C92.4413 200.656 95.847 200.114 96.9318 199.926C97.0359 199.909 97.1324 199.861 97.2088 199.789C97.2851 199.716 97.3377 199.622 97.3595 199.519L97.9071 197.329C97.9271 197.261 97.9306 197.19 97.9175 197.12C97.9044 197.051 97.8749 196.986 97.8315 196.93C97.7882 196.875 97.7322 196.83 97.6683 196.801C97.6043 196.771 97.5342 196.757 97.4638 196.76L90.7515 196.891C90.6218 196.89 90.4964 196.938 90.4 197.024C90.3036 197.111 90.2429 197.231 90.23 197.36Z" fill="white"/>
<path d="M83.7477 33.5838C88.186 40.0092 75.9245 37.6988 73.2542 43.7383C73.2646 37.6988 73.7653 33.0362 83.7477 33.5838Z" fill="#263238"/>
<path d="M94.262 90.7667C87.7896 92.1331 74.2971 95.2729 71.7207 90.7667C73.4001 89.6141 90.5173 89.8227 94.262 90.7667Z" fill="#407BFF"/>
<path d="M78.7933 94.3015C78.3656 94.3015 77.964 94.3015 77.5989 94.2702C73.3848 94.0512 72.1801 92.7577 72.1331 92.7056C72.1195 92.6929 72.1087 92.6776 72.1013 92.6605C72.0939 92.6435 72.0901 92.6251 72.0901 92.6065C72.0901 92.5879 72.0939 92.5695 72.1013 92.5525C72.1087 92.5354 72.1195 92.5201 72.1331 92.5074C72.1595 92.4813 72.1951 92.4666 72.2322 92.4666C72.2693 92.4666 72.3049 92.4813 72.3313 92.5074C72.3313 92.5074 73.5309 93.7643 77.6041 93.9834C81.6774 94.2024 90.9557 93.055 93.7721 91.7407C93.8069 91.7258 93.8461 91.7245 93.8818 91.7371C93.9175 91.7496 93.9472 91.7751 93.9651 91.8085C93.9796 91.844 93.9801 91.8836 93.9666 91.9194C93.953 91.9552 93.9264 91.9846 93.8921 92.0015C91.2478 93.248 83.3672 94.3015 78.7933 94.3015Z" fill="white"/>
<path d="M77.2491 104.493C77.1088 104.488 76.9737 104.439 76.8632 104.353C74.777 102.882 76.7537 95.6896 77.8646 93.7286C77.88 93.7021 77.9035 93.6812 77.9315 93.6687C77.9596 93.6563 77.9908 93.6531 78.0209 93.6594C78.0509 93.6658 78.0781 93.6814 78.0988 93.7041C78.1194 93.7268 78.1323 93.7555 78.1358 93.786C78.214 94.7926 78.8816 103.633 77.4995 104.426C77.4236 104.47 77.3372 104.494 77.2491 104.493ZM77.8854 94.318C76.7902 96.7119 75.2777 102.908 77.0249 104.118C77.2022 104.243 77.3013 104.212 77.3586 104.18C78.2453 103.669 78.1671 98.1878 77.8854 94.318Z" fill="white"/>
<path d="M79.4556 102.788C79.3464 102.784 79.2386 102.761 79.1375 102.72C76.7279 101.771 77.2495 95.851 77.8545 93.7596C77.8635 93.7313 77.8808 93.7063 77.904 93.6878C77.9271 93.6692 77.9553 93.6579 77.9848 93.6553C78.0148 93.6558 78.0441 93.6649 78.069 93.6815C78.094 93.6982 78.1137 93.7216 78.1257 93.7491C78.4125 94.5523 80.8951 101.604 79.8259 102.642C79.7266 102.737 79.5935 102.79 79.4556 102.788ZM78.0109 94.2863C77.4529 96.7115 77.239 101.666 79.2418 102.454C79.5025 102.558 79.5964 102.454 79.6277 102.454C80.3057 101.781 79.0853 97.3635 78.0109 94.2863Z" fill="white"/>
<path d="M75.6896 99.2932C75.6556 99.2936 75.6226 99.2817 75.5967 99.2596C75.5708 99.2376 75.5538 99.2069 75.5487 99.1733C75.2984 97.5721 76.8422 94.0204 77.9479 93.6657C77.9653 93.6593 77.9839 93.6564 78.0024 93.6574C78.021 93.6584 78.0392 93.6632 78.0558 93.6715C78.0724 93.6799 78.0872 93.6915 78.0991 93.7058C78.111 93.72 78.1199 93.7366 78.1252 93.7544C78.1369 93.7904 78.1343 93.8295 78.1177 93.8636C78.1012 93.8976 78.0721 93.9239 78.0365 93.9369C77.0664 94.2447 75.6061 97.6921 75.8304 99.1524C75.8349 99.1896 75.825 99.2271 75.8027 99.2572C75.7804 99.2873 75.7473 99.3077 75.7104 99.3141L75.6896 99.2932Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_6768_3586">
<rect width="240" height="240" fill="white"/>
</clipPath>
</defs>
</svg>

    )
}
