import React, { ReactElement } from "react";

interface IProps {
  size?: number;
  color?: string;
}

export default function User({
  color = "currentColor",
  size = 16,
}: IProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8216 5.55172C13.8216 7.64831 12.1137 9.35345 10.0001 9.35345C7.88656 9.35345 6.17871 7.64831 6.17871 5.55172C6.17871 3.45514 7.88656 1.75 10.0001 1.75C12.1137 1.75 13.8216 3.45514 13.8216 5.55172Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M2.79609 17.7501C3.20079 14.4742 6.2237 11.8535 10 11.8535C13.7763 11.8535 16.7992 14.4742 17.2039 17.7501H2.79609Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
}
