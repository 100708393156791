import React, { ReactElement } from "react";
import Typography from "../../components/Typography";
import "./index.scss";
import Diagram from "../../icons/Diagram";

export default function Features(): ReactElement {
  return (
    <div className={"pq-features"} id="how-we-do-it">
      <div className="pq-features_figure">
        <div className="pq-features_figure_topic"><Typography variant="body_small">How do we do it?</Typography></div>
        <div className="pq-features_figure_title"><Typography variant="title_small" color="white">With our cutting-edge AI and ML technology</Typography></div>
        <div className="pq-features_figure_diagram"><Diagram/></div>
      </div>
      <div className="pq-features_process" id="process">
        <div className="pq-features_process_topic"><Typography variant="body_small">The process</Typography></div>
        <div className="pq-features_process_content">
            <div className="pq-features_process_step">
              <div className="pq-features_process_step_heading">
                <div className="pq-features_process_step_count"><Typography variant="body_regular">1</Typography></div>
              </div>
              <div className="pq-features_process_step_body"><Typography variant="body_regular">Get the requirements for the transactions</Typography></div>
            </div>
            <div className="pq-features_process_step">
              <div className="pq-features_process_step_heading">
                <div className="pq-features_process_step_count"><Typography variant="body_regular">2</Typography></div>
              </div>
              <div className="pq-features_process_step_body"><Typography variant="body_regular">Our system identifies potential assets or companies</Typography></div>
            </div>
            <div className="pq-features_process_step">
              <div className="pq-features_process_step_heading">
                <div className="pq-features_process_step_count"><Typography variant="body_regular">3</Typography></div>
              </div>
              <div className="pq-features_process_step_body"><Typography variant="body_regular">Confirms with the company on the other end of the deal</Typography></div>
            </div>
            <div className="pq-features_process_step">
              <div className="pq-features_process_step_heading">
                <div className="pq-features_process_step_count"><Typography variant="body_regular">4</Typography></div>
              </div>
              <div className="pq-features_process_step_body"><Typography variant="body_regular">We disclose the details of the transaction to you</Typography></div>
            </div>
        </div>
      </div>
    </div>
  );
}
