import React, { ReactElement } from "react";
import "./index.scss";

interface ITextAreaProps {
  onChange: (text: string) => void;
  icon?: ReactElement;
  placeholder?: string;
  value?: string;
  error: string;
}

export default function TextArea({
  onChange,
  icon,
  placeholder,
  value,
  error
}: ITextAreaProps): ReactElement {
  return (
    <div className="pq-text_area" style={error ? {border: '1px solid #DE6666'} : {}}>
      {icon ? <div className="pq-text_area__icon">{icon}</div> : null}
      <textarea
        rows={5}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        value={value}
      />
    </div>
  );
}
