import "./index.scss";

import Link from "../../components/Link";
import LogoDark from "../../icons/LogoDark";
import React from "react";
import Typography from "../../components/Typography";

export default function Header(): React.ReactElement {
  const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 70) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <div className={`pq-header ${scrolled ? "scrolled" : ""}`}>
      <div className={"pq-header_logo"}>
        <LogoDark />
      </div>
      <div className="pq-header_buttons">
        <Link to="#how-we-do-it">
          <Typography variant="link" color="#0066FF">
            How do we do it?
          </Typography>
        </Link>
        <Link to="#process">
          <Typography variant="link" color="#0066FF">
            The process
          </Typography>
        </Link>
        <Link to="#who-are-we">
          <Typography variant="link" color="#0066FF">
            Who are we?
          </Typography>
        </Link>
        <Link to="#contact">
          <Typography variant="link" color="#0066FF">
            Get in touch
          </Typography>
        </Link>
      </div>
    </div>
  );
}
