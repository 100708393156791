import React, { ReactElement } from "react";

interface IProps {
  size?: number;
  color?: string;
}

export default function OutlinedPhone({
  color = "currentColor",
  size = 16,
}: IProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2127:5553)">
        <path
          d="M14.5218 13.8327L17.1123 16.7301C17.4579 17.1167 17.4248 17.7103 17.0381 18.056C14.9479 19.9249 11.8015 19.9723 9.65584 18.1674L8.0289 16.7988C6.3611 15.3958 4.90117 13.7629 3.69291 11.9491L2.51425 10.1797C0.959783 7.8462 1.35776 4.72474 3.44801 2.85589C3.83463 2.51022 4.42827 2.54342 4.77394 2.93004L7.36442 5.8274C7.73253 6.23912 7.69717 6.8713 7.28546 7.23941L6.15453 8.25055C5.97834 8.40808 5.92272 8.66068 6.01645 8.87765C7.10013 11.3862 8.96821 13.4756 11.3403 14.8322C11.5454 14.9495 11.8027 14.9224 11.9789 14.7649L13.1098 13.7537C13.5215 13.3856 14.1537 13.421 14.5218 13.8327Z"
          fill="none"
          fillOpacity="0.25"
          stroke={color}
          strokeWidth="1.2"
        />
        <path
          d="M11.543 6.04272C13.043 6.04272 15.943 6.94272 15.543 10.5427M12.043 2.54272C14.043 2.54272 18.243 3.84272 19.043 9.04272"
          stroke={color}
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2127:5553">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
