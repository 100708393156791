import "./index.scss";

import React, { useState } from "react";

import Contact from "../sections/Contact";
import Explainer from "../sections/Explainer";
import Features from "../sections/Features";
import Footer from "../sections/Footer";
import Header from "../sections/Header";
import { Helmet } from "react-helmet";
import Landing from "../sections/Landing";

const IndexPage = (): React.ReactElement => {
  const [query, setQuery] = useState<string>("");

  return (
    <div className={"pq-index"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PharmIQ</title>
        <html lang="en" />
      </Helmet>
      <Header/>
      <Landing setQuery={(s) => setQuery(s)} />
      <Features />
      <Explainer />
      <Contact query={query} />
      <Footer />
    </div>
  );
};

export default IndexPage;
