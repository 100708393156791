import React, { ReactElement } from "react";
import Typography from "../Typography";
import './index.scss'
import SubmissionSuccess from "../../icons/SubmissionSuccess";

export default function FormSuccess() : ReactElement {
    return(
        <div className="pq-form_success">
            <div><SubmissionSuccess/></div>
            <div className="pq-form_success_heading"><Typography variant="body_regular" color="black">Success! We've received your information.</Typography></div>
            <div className="pq-form_success_content"><Typography variant="body_small" color="#0B2143">Thank you! Your information has been submitted successfully. Our team will be in touch with you as soon as possible.</Typography></div>
        </div>
    )
}