import React, { ReactElement } from "react";

interface IProps {
  size?: number;
  color?: string;
}

export default function OutlinedMail({
  color = "currentColor",
  size = 16,
}: IProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.0875 2H16.9125C17.7011 1.99995 18.4599 2.31755 19.0331 2.88767C19.6064 3.45779 19.9506 4.23719 19.9953 5.066L20 5.25V14.75C20.0001 15.5801 19.6983 16.3788 19.1567 16.9822C18.6151 17.5856 17.8747 17.948 17.0873 17.995L16.9125 18H4.0875C3.29887 18.0001 2.54011 17.6824 1.96688 17.1123C1.39365 16.5422 1.0494 15.7628 1.00475 14.934L1 14.75V5.25C0.999948 4.41986 1.30168 3.62117 1.84329 3.01777C2.3849 2.41437 3.12533 2.052 3.9127 2.005L4.0875 2H16.9125H4.0875ZM18.575 7.373L10.8325 11.663C10.7451 11.7116 10.649 11.7405 10.5504 11.7479C10.4517 11.7553 10.3527 11.7411 10.2596 11.706L10.1685 11.664L2.425 7.374V14.75C2.42502 15.1892 2.58191 15.6123 2.86455 15.9354C3.14718 16.2585 3.53488 16.4579 3.9507 16.494L4.0875 16.5H16.9125C17.3299 16.5 17.732 16.3347 18.0389 16.037C18.3459 15.7392 18.5352 15.3309 18.5693 14.893L18.575 14.75V7.373ZM16.9125 3.5H4.0875C3.67027 3.50002 3.2683 3.66517 2.96138 3.96268C2.65446 4.26019 2.46503 4.6683 2.4307 5.106L2.425 5.25V5.679L10.5 10.152L18.575 5.678V5.25C18.575 4.81065 18.4179 4.38739 18.1351 4.06429C17.8523 3.74119 17.4643 3.5419 17.0483 3.506L16.9125 3.5Z"
        fill={color}
      />
    </svg>
  );
}
