import "./index.scss";

import ContactForm from "../../components/ContactForm";
import React from "react";
import Tag from "../../components/Tag";
import Typography from "../../components/Typography";

interface Props {
  query: string;
}

export default function Contact({ query }: Props): React.ReactElement {
  return (
    <div className="pq-contact" id="contact">
      <div className="pq-contact__text__wrapper">
        <Tag color={"yellow"}>Get in touch</Tag>
        <Typography variant="body_regular">{`At PharmIQ, we value each and every inquiry we receive and strive to provide prompt and meaningful responses to our valued clients. We look forward to connecting with you soon and assisting you to the best of our abilities.`}</Typography>
      </div>
      <div className="pq-contact__form__wrapper">
        <ContactForm query={query} />
      </div>
    </div>
  );
}
