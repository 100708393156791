import "./index.scss";

import * as React from "react";

import Button from "../../components/Button";
import Typography from "../../components/Typography";

const Explainer = (): React.ReactElement => {
  
  function scrollToTop() {
    window.scrollTo(0,0);
  }

  return (
    <div className={"pq-explainer"} id="who-are-we">
      <div className="pq-explainer_left">
        <div className="pq-explainer_left_heading">
          <div className="pq-explainer_left_topic"><Typography variant="body_small">Who are we?</Typography></div>
          <div className="pq-explainer_left_title"><Typography variant="title_small" color="black">We use data as our superpower to facilitate the process</Typography></div>
        </div>

        <div className="pq-explainer_left_body">
          <div><Typography variant="body_small" color="#0B2143">A combination of pharmaceutical and computer science veterans, with a vision to optimise time-consuming deals within the industry. We cater to the markets covered by the USFDA, and plan to go global.</Typography></div>
          <div>
            <Button size="xs" onClick={scrollToTop}>
              Contact Us Now
            </Button>
          </div>
        </div>
      </div>
      <div className="pq-explainer_right">
        <iframe
          src="https://www.youtube-nocookie.com/embed/ERYFlv58-is?modestbranding=1&rel=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default Explainer;
