import "./index.scss";

import * as React from "react";

import Button from "../../components/Button";
import LandingGraphicFour from "../../icons/LandingGraphicFour";
import LandingGraphicOne from "../../icons/LandingGraphicOne";
import LandingGraphicThree from "../../icons/LandingGraphicThree";
import LandingGraphicTwo from "../../icons/LandingGraphicTwo";
import SampleBox from "../../components/SampleBox";
import Tag from "../../components/Tag";
import TextInput from "../../components/TextInput";
import Typography from "../../components/Typography";
import { navigate } from "gatsby";

interface Props {
  setQuery: (s: string) => void;
}

const Landing = ({ setQuery }: Props): React.ReactElement => {

  return (
    <div className={"pq-landing"}>
      <div className="pq-landing_body">
        <div className="pq-landing_body_left">
          <div className="pq-landing_body__title">
            <Typography variant="title_large" color="black">
              {`We'll Bring The Deals,`}
              <br /> You Close Them.
            </Typography>
          </div>
          <div className="pq-landing_body_samples">
            <Typography variant="display" color="#36454F">We helped our clients to</Typography>
            <SampleBox data={[
            "acquire oral solid ANDAs.",
            "in-license tentatively approved drugs.",
            "expand their Pain Management Pipeline.",
            "acquire a New Jersey based oral liquid facility.",
            "find a marketing partner.",
            "acquire oral liquid ANDAs.",
            "find a CMO that can manufacture controlled substances.",
            "divest their generic portfolio.",
            "find investors for their 505(b)2 filing."
          ]} showCount={1}/>
          </div>
        </div>
        <div className="pq-landing_body_right">
          <Tag color={"purple"}>How can we help you?</Tag>
          <div className="pq-landing_body_right_content">
            <Typography variant="body_regular" color="#0B2143">
              {
                "Whatever your pharmaceutical asset needs are, PharmIQ will Deliver - whether it's in-licensing or acquisition and funding opportunities, all from Generic ANDAs to 505b(2)s. "
              }
            </Typography>
            <div className={"pq-landing_body_right_content_cta"}>
              <TextInput
                onChange={(s) => setQuery(s)}
                placeholder={"What are you looking for?"}
                type={"text"}
              />
              <Button onClick={() => navigate("#contact")} size={"xs"}>
                Search
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
