import "./index.scss";

import React, { ReactElement, useEffect } from "react";

import Axios from "axios";
import Building from "../../icons/Building";
import Button from "../Button";
import OutlinedMail from "../../icons/OutlinedMail";
import OutlinedPhone from "../../icons/OutlinedPhone";
import TextInput from "../TextInput";
import TextArea from "../TextArea";
import Typography from "../Typography";
import User from "../../icons/User";
import { validateEmail } from "../../utils/utils";
import FormSuccess from "../FormSuccess";
import FormFailure from "../FormFailure";

interface Props {
  query: string;
}

export default function ContactForm({ query }: Props): ReactElement {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [misc, setMisc] = React.useState("");
  const [role, setRole] = React.useState("");

  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [companyError, setCompanyError] = React.useState("");
  const [validationError, setValidationError] = React.useState("");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    console.log(query)
    if (query) setMisc(`I'm looking for ${query}`);
  }, [query]);

  const revertForm = () => {
    setError("");
  };

  const handleSubmit = async () => {
    setNameError("");
    setEmailError("");
    setCompanyError("");
    setValidationError("");
    setError("");

    if (!name) {
      setNameError("Name is required");
      return;
    } else if (!email) {
      setEmailError("Email is required");
      return;
    } else if (!company) {
      setCompanyError("Company is required");
      return;
    }

    if (!validateEmail(email)) {
      setValidationError("Email is not valid");
      return;
    }

    try {
      setLoading(true);
      const res = await Axios.post(
        "https://srvl.pharmiq.co/landingPageContactForm",
        {
          name,
          email,
          company,
          phone,
          role,
          misc,
        }
      );
      if (res.status === 200) {
        setName("");
        setEmail("");
        setCompany("");
        setPhone("");
        setRole("");
        setMisc("");
        setSuccess("Thank you for contacting us.");
      }
    } catch (err) {
      setError("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="pq-form-container">
      {success !== "" ? (
        <FormSuccess />
      ) : error !== "" ? (
        <FormFailure revertForm={revertForm} />
      ) : (
        <div className="pq-contact_form">
          <div className="pq-contact_form_title">
            <Typography variant="title_small" color={"#0B2143"}>
              {"Let's talk"}
            </Typography>
          </div>

          <form className="pq-contact_form__input_container">
            <TextInput
              placeholder="Full Name"
              icon={<User />}
              value={name}
              onChange={(t) => setName(t)}
              error={nameError}
            />

            {nameError !== "" ? (
              <div
                className="pq-contact_form__msg"
                data-error={nameError !== ""}
              >
                <Typography
                  variant="body_small"
                  color={nameError ? "#e3001b" : "#07ae50"}
                  style={{ textAlign: "center" }}
                >
                  {nameError ? nameError : success}
                </Typography>
              </div>
            ) : null}

            <TextInput
              placeholder="Email Address"
              value={email}
              icon={<OutlinedMail />}
              onChange={(t) => setEmail(t)}
              error={emailError ? emailError : validationError}
            />

            {emailError !== "" ? (
              <div
                className="pq-contact_form__msg"
                data-error={emailError !== ""}
              >
                <Typography
                  variant="body_small"
                  color={emailError ? "#e3001b" : "#07ae50"}
                  style={{ textAlign: "left" }}
                >
                  {emailError ? emailError : success}
                </Typography>
              </div>
            ) : null}

            {validationError !== "" ? (
              <div
                className="pq-contact_form__msg"
                data-error={validationError !== ""}
              >
                <Typography
                  variant="body_small"
                  color={validationError ? "#e3001b" : "#07ae50"}
                  style={{ textAlign: "center" }}
                >
                  {validationError ? validationError : success}
                </Typography>
              </div>
            ) : null}

            <TextInput
              placeholder="Organization"
              value={company}
              icon={<Building />}
              onChange={(t) => setCompany(t)}
              error={companyError}
            />

            {companyError !== "" ? (
              <div
                className="pq-contact_form__msg"
                data-error={companyError !== ""}
              >
                <Typography
                  variant="body_small"
                  color={companyError ? "#e3001b" : "#07ae50"}
                  style={{ textAlign: "center" }}
                >
                  {companyError ? companyError : success}
                </Typography>
              </div>
            ) : null}

            <TextInput
              placeholder="Phone Number (Optional)"
              value={phone}
              icon={<OutlinedPhone />}
              onChange={(t) => setPhone(t)}
              error={""}
            />

            <TextInput
              placeholder="Role (Optional)"
              value={role}
              onChange={(t) => setRole(t)}
              error={""}
            />

            <TextArea
              placeholder="Additional Details (Optional)"
              value={misc}
              onChange={(t) => setMisc(t)}
              error={""}
            />

            <Button
              size="xl"
              onClick={() => (!loading ? handleSubmit() : null)}
            >
              {!loading ? "Get Started" : "Notifying our team"}
            </Button>
          </form>
        </div>
      )}
    </div>
  );
}
