import React, { ReactElement } from "react";
import "./index.scss";

interface ITextInputProps {
  type?: "text" | "password" | "email" | "tel" ;
  onChange: (text: string) => void;
  icon?: ReactElement;
  placeholder?: string;
  value?: string;
  error?: string;
}

export default function TextInput({
  type = "text",
  onChange,
  icon,
  placeholder,
  value,
  error
}: ITextInputProps): ReactElement {
  return (
    <div className="pq-text_input" style={error ? {border: '1px solid #DE6666'} : {}}>
      {icon ? <div className="pq-text_input__icon">{icon}</div> : null}
      <input
        type={type}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        value={value}
      />
    </div>
  );
}
