import React, { ReactElement } from "react";

interface IProps {
  size?: number;
  color?: string;
}

export default function Building({
  color = "currentColor",
  size = 16,
}: IProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="12.75"
        y="9.75"
        width="7.5"
        height="9.5"
        rx="0.75"
        fill="none"
        stroke={color}
        strokeWidth="1.5"
      />
      <rect
        x="0.75"
        y="1.75"
        width="12.5"
        height="17.5"
        rx="0.75"
        fill="none"
        stroke={color}
        strokeWidth="1.5"
      />
      <rect x="3" y="4" width="2" height="2" fill={color} />
      <rect x="6" y="4" width="2" height="2" fill={color} />
      <rect x="9" y="4" width="2" height="2" fill={color} />
      <rect x="3" y="7" width="2" height="2" fill={color} />
      <rect x="6" y="7" width="2" height="2" fill={color} />
      <rect x="9" y="7" width="2" height="2" fill={color} />
      <rect x="3" y="10" width="2" height="2" fill={color} />
      <rect x="6" y="10" width="2" height="2" fill={color} />
      <rect x="9" y="10" width="2" height="2" fill={color} />
      <rect x="14" y="12" width="3" height="1" fill={color} />
      <rect x="14" y="14" width="3" height="1" fill={color} />
      <rect x="14" y="16" width="3" height="1" fill={color} />
      <rect x="5" y="15" width="4" height="5" fill={color} />
    </svg>
  );
}
