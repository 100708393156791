import React, {ReactElement} from "react";

export default function SubmissionSuccess(): ReactElement {
    return(

<svg width="234" height="230" viewBox="0 0 234 230" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M216.99 46.0138C215.345 43.3608 213.534 40.8146 211.568 38.3905C195.515 18.6295 174.525 10.2662 150.55 4.66417C125.785 -1.12159 96.7891 -2.9194 73.0118 7.50987C67.416 9.99303 62.098 13.2708 57.8912 17.7405C41.9818 34.5813 36.5249 62.785 18.8098 85.0788C1.09468 107.373 -7.54709 135.144 8.35733 161.506C15.0892 172.67 23.6714 181.867 33.8262 189.963C46.3275 199.895 57.1124 213.334 71.5633 221.106C86.7484 229.271 106.974 232.117 123.781 228.377C142.955 224.111 154.201 207.146 168.835 195.599C180.374 186.481 194.18 180.512 205.486 171.105C222.809 156.703 235.945 133.53 233.763 110.571C231.619 88.2821 228.861 65.1192 216.99 46.0138Z" fill="#DEE8FF"/>
<g clip-path="url(#clip0_6768_4029)">
<path d="M38 108.5L118 53L198 108.5V197H38V108.5Z" fill="white"/>
<path d="M185.781 100.803C185.731 100.803 185.682 100.787 185.641 100.759L118.2 53.7065C117.995 53.564 117.751 53.4878 117.501 53.4883C117.251 53.4887 117.008 53.5657 116.803 53.7089L49.8471 100.758C49.7941 100.795 49.7286 100.81 49.6649 100.799C49.6012 100.788 49.5445 100.752 49.5074 100.699C49.4702 100.645 49.4556 100.58 49.4668 100.516C49.478 100.452 49.514 100.395 49.567 100.358L116.523 53.3088C116.809 53.1083 117.151 53.0006 117.5 53C117.85 52.9994 118.191 53.1061 118.479 53.3056L185.92 100.358C185.963 100.387 185.995 100.43 186.012 100.479C186.028 100.529 186.029 100.582 186.013 100.631C185.998 100.681 185.967 100.724 185.925 100.755C185.883 100.786 185.832 100.803 185.781 100.803Z" fill="#3F3D56"/>
<path d="M53.6731 102.47L117.567 55.0322L181.946 105.846L120.859 142.124L87.6944 134.55L53.6731 102.47Z" fill="#E6E6E6"/>
<path d="M90.4938 164.531H58.9285C58.7347 164.531 58.5427 164.493 58.3636 164.419C58.1844 164.345 58.0216 164.236 57.8845 164.099C57.7474 163.962 57.6386 163.799 57.5643 163.619C57.4901 163.44 57.4519 163.248 57.4519 163.054C57.4519 162.859 57.4901 162.667 57.5643 162.488C57.6386 162.308 57.7474 162.145 57.8845 162.008C58.0216 161.871 58.1844 161.762 58.3636 161.688C58.5427 161.614 58.7347 161.576 58.9285 161.576H90.4938C90.6876 161.576 90.8796 161.614 91.0587 161.688C91.2378 161.762 91.4006 161.871 91.5378 162.008C91.6749 162.145 91.7837 162.308 91.8579 162.488C91.9322 162.667 91.9704 162.859 91.9704 163.054C91.9704 163.248 91.9322 163.44 91.8579 163.619C91.7837 163.799 91.6749 163.962 91.5378 164.099C91.4006 164.236 91.2378 164.345 91.0587 164.419C90.8796 164.493 90.6876 164.531 90.4938 164.531Z" fill="#0066FF"/>
<path d="M70.2534 158.179H58.9285C58.7347 158.18 58.5427 158.142 58.3636 158.067C58.1844 157.993 58.0216 157.884 57.8845 157.747C57.7474 157.61 57.6386 157.447 57.5643 157.268C57.4901 157.088 57.4519 156.896 57.4519 156.702C57.4519 156.508 57.4901 156.316 57.5643 156.136C57.6386 155.957 57.7474 155.794 57.8845 155.657C58.0216 155.519 58.1844 155.411 58.3636 155.337C58.5427 155.262 58.7347 155.224 58.9285 155.225H70.2534C70.4472 155.224 70.6392 155.262 70.8184 155.337C70.9975 155.411 71.1603 155.519 71.2974 155.657C71.4346 155.794 71.5434 155.957 71.6176 156.136C71.6918 156.316 71.73 156.508 71.73 156.702C71.73 156.896 71.6918 157.088 71.6176 157.268C71.5434 157.447 71.4346 157.61 71.2974 157.747C71.1603 157.884 70.9975 157.993 70.8184 158.067C70.6392 158.142 70.4472 158.18 70.2534 158.179Z" fill="#0066FF"/>
<path d="M118.466 128.893C118.226 128.893 117.988 128.847 117.766 128.756L78.3606 112.376V61.1046C78.3611 60.6512 78.5411 60.2166 78.8611 59.896C79.1812 59.5754 79.615 59.3951 80.0676 59.3945H155.664C156.117 59.3951 156.551 59.5754 156.871 59.896C157.191 60.2166 157.371 60.6512 157.371 61.1046V112.412L157.297 112.443L119.187 128.748C118.958 128.844 118.713 128.893 118.466 128.893Z" fill="white"/>
<path d="M118.466 129.015C118.21 129.015 117.957 128.966 117.72 128.869L78.2388 112.458V61.1047C78.2393 60.6189 78.4322 60.1532 78.7751 59.8097C79.1179 59.4662 79.5828 59.273 80.0677 59.2725H155.664C156.149 59.273 156.614 59.4662 156.957 59.8097C157.3 60.1532 157.493 60.6189 157.493 61.1047V112.492L119.235 128.86C118.991 128.963 118.73 129.015 118.466 129.015ZM78.7265 112.131L117.906 128.417C118.271 128.566 118.68 128.563 119.044 128.41L157.005 112.17V61.1047C157.005 60.7485 156.864 60.4069 156.612 60.1551C156.361 59.9032 156.02 59.7615 155.664 59.7611H80.0677C79.7121 59.7615 79.3712 59.9032 79.1198 60.1551C78.8684 60.4069 78.7269 60.7485 78.7265 61.1047L78.7265 112.131Z" fill="#3F3D56"/>
<path d="M185.293 100.313H185.244L157.249 112.289L118.946 128.674C118.796 128.737 118.636 128.77 118.473 128.771C118.311 128.771 118.15 128.74 118 128.679L78.4825 112.255L49.7997 100.333L49.7558 100.313H49.707C49.2544 100.314 48.8205 100.494 48.5005 100.815C48.1805 101.135 48.0005 101.57 48 102.024V176.289C48.0005 176.743 48.1805 177.178 48.5005 177.498C48.8205 177.819 49.2544 177.999 49.707 178H185.293C185.746 177.999 186.179 177.819 186.499 177.498C186.819 177.178 186.999 176.743 187 176.289V102.024C186.999 101.57 186.819 101.135 186.499 100.815C186.179 100.494 185.746 100.314 185.293 100.313ZM186.512 176.289C186.512 176.613 186.384 176.924 186.155 177.153C185.926 177.382 185.616 177.511 185.293 177.511H49.707C49.3837 177.511 49.0736 177.382 48.845 177.153C48.6164 176.924 48.4878 176.613 48.4877 176.289V102.024C48.4882 101.708 48.6105 101.404 48.8291 101.177C49.0477 100.949 49.3456 100.815 49.6607 100.802L78.4825 112.782L117.812 129.131C118.238 129.304 118.715 129.302 119.139 129.123L157.249 112.819L185.342 100.802C185.656 100.816 185.953 100.95 186.171 101.178C186.389 101.405 186.512 101.708 186.512 102.024V176.289Z" fill="#3F3D56"/>
<path d="M118.072 114.354C116.617 114.356 115.201 113.885 114.037 113.011L113.965 112.957L98.7697 101.302C98.066 100.762 97.4754 100.088 97.0316 99.3193C96.5878 98.5505 96.2995 97.7016 96.1831 96.8212C96.0668 95.9408 96.1247 95.046 96.3536 94.188C96.5824 93.3299 96.9777 92.5255 97.5169 91.8205C98.0561 91.1155 98.7286 90.5238 99.496 90.0792C100.263 89.6346 101.111 89.3458 101.99 89.2293C102.869 89.1127 103.762 89.1707 104.618 89.4C105.475 89.6292 106.278 90.0252 106.981 90.5654L116.824 98.1258L140.083 67.7387C140.622 67.0339 141.295 66.4424 142.062 65.9981C142.83 65.5537 143.677 65.2652 144.556 65.149C145.435 65.0328 146.328 65.0911 147.185 65.3207C148.041 65.5503 148.844 65.9466 149.548 66.487L149.403 66.6837L149.551 66.4899C150.97 67.5825 151.899 69.1944 152.133 70.972C152.367 72.7496 151.888 74.5477 150.801 75.9718L123.443 111.714C122.81 112.538 121.996 113.205 121.065 113.662C120.134 114.12 119.109 114.357 118.072 114.354Z" fill="#0066FF"/>
</g>
<defs>
<clipPath id="clip0_6768_4029">
<rect width="139" height="125" fill="white" transform="translate(48 53)"/>
</clipPath>
</defs>
</svg>

    )
}
