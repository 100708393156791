import "./index.scss";

import React, { ReactElement, useEffect, useState } from "react";
import { a, useTrail } from "@react-spring/web";

import Typography from "../Typography";

interface ISampleBoxProps {
  data: string[];
  showCount: number;
}

export default function SampleBox({
  data,
  showCount,
}: ISampleBoxProps): ReactElement {
  const [idx, setIdx] = useState(Math.floor(Math.random()*100) % data.length);
  const Trail: React.FC<{ open: boolean }> = ({ open, children }) => {
    const items = React.Children.toArray(children);
    const trail = useTrail(items.length, {
      config: { mass: 5, tension: 2000, friction: 200 },
      opacity: open ? 1 : 0,
      x: open ? 0 : 20,
      height: open ? 110 : 0,
      from: { opacity: 0, x: 20, height: 0 },
    });
    return (
      <div>
        {trail.map(({ height, ...style }, index) => (
          <a.div key={index} className={"trail_text"} style={style}>
            <a.div style={{ height }}>{items[index]}</a.div>
          </a.div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIdx((t) => t + 1);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const currElems = [];

  for (let i = 0; i < showCount; i++) {
    currElems.push(data[(idx + showCount * i) % data.length]);
  }

  return (
    <div className="pq-sample_box">
      <Trail open={true}>
        {currElems.map((v, i) => (
          <Typography variant="title_regular" key={i} color="#7393B3">{v}</Typography>
        ))}
      </Trail>
    </div>
  );
}
