import React, { ReactElement } from "react";
import SubmissionFailure from "../../icons/SubmissionFailure";
import Typography from "../Typography";
import './index.scss'

export default function FormFailure(props: {revertForm: () => void}) : ReactElement {
    return(
        <div className="pq-form_failure">
            <div><SubmissionFailure/></div>
            <div className="pq-form_failure_heading"><Typography variant="body_regular" color="black">We're sorry. It seems there was an issue submitting your information.</Typography></div>
            <div className="pq-form_failure_content"><Typography variant="body_small" color="#0B2143">Please check your input and try again. If the problem persists, you can contact our team directly at sales@pharmiq.co for assistance.</Typography></div>
            <div className="pq-form_failure_label" onClick= {() => props.revertForm()}><Typography variant="body_small">Resubmit</Typography></div>
        </div>
    )
}