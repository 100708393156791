import React, { ReactElement, ReactNode } from "react";
import Typography from "../Typography";
import "./index.scss";

interface ITagProps {
  children: ReactNode;
  color: "yellow"|"purple"
}

export default function Tag({ children, color }: ITagProps): ReactElement {
  
  const colorMap = {
    "yellow": "#FFCD4E",
    "purple": "#6B59FF"
  }
  
  const colorTextMap = {
    "yellow": "#000",
    "purple": "#FFF"
  }
  
  return (
    <div className="pq-tag" style={{backgroundColor: colorMap[color]}}>
      <Typography variant="body_small" color={colorTextMap[color]}>{children}</Typography>
    </div>
  );
}
