import "./index.scss";

import React, { ReactElement } from "react";

interface IButton {
  children?: React.ReactNode;
  onClick: () => void;
  size: "xs" | "sm" | "lg" | "xl";
  variant?: "primary" | "secondary";
}

export default function Button({
  children,
  onClick,
  size,
  variant = "primary"
}: IButton): ReactElement {
  return (
    <div className={`pq-button ${size} ${variant}`} onClick={onClick} tabIndex={0}>
      {children}
    </div>
  );
}
